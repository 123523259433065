import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import {
  AppointmentInformationResType,
  StoreDataResType,
} from 'models/stores-model'
import { useEffect, useState } from 'react'
import {
  consoleLog,
  getYYYYMMDD,
  removeTrailingSlashes,
} from '../../commons/utils'
import {
  ThankYouPageComponent,
  handleOnDownloadICS,
} from '../../components/Mappa/components/AppuntamentoModal'
import {
  StyledAppuntamentoContent,
  StyledAppuntamentoModal,
  StyledAppuntamentoSidebar,
  StyledContainer,
  StyledHTMLSidebar,
  StyledPrenotaAppuntamentoBody,
} from '../../pages-style/prenota-appuntamento.style'
import styled from 'styled-components'
import { MakeAppointment } from 'components/MakeAppointment/MakeAppointment'
import { usePrivacyLinks } from 'hooks/use-privacy-links'

const PrenotaAppuntamentoPage = () => {
  const privacyLinks = usePrivacyLinks()
  const [rawStores, setRawStores] = useState<StoreDataResType[]>([])
  const [data, setData] = useState<AppointmentInformationResType>()
  const [selectedSpazioLabel, setSelectedSpazioLabel] = useState<string>()
  const [orarioLabel, setOrarioLabel] = useState<string>()
  const [date, setDate] = useState<Date>()
  const [showTY, setShowTY] = useState<'ok' | 'ko' | undefined>()

  const privacyId = 'privacy-form-appuntamento'
  const privacyLink = privacyLinks.find(
    (link) => link.privacy_id === privacyId
  )?.url

  consoleLog('PRIVACY LINK in Prenota Appuntamento:', privacyLink)

  function handleOnSubmit({
    orario,
    date,
    selectedSpazioLabel,
    showTY,
  }: {
    orario: string | undefined
    date: Date | undefined
    selectedSpazioLabel: string | undefined
    showTY: 'ok' | 'ko'
  }) {
    setDate(date)
    setSelectedSpazioLabel(selectedSpazioLabel)
    setOrarioLabel(orario)
    setShowTY(showTY)
  }

  useEffect(() => {
    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_CASA_URL
      )}/a2a-api/get-appointment-information?_format=json`
    )
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => {
        console.warn(err)
      })

    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_CASA_URL
      )}/get-store-locator-json`
    )
      .then((res) => res.json() as Promise<StoreDataResType[]>)
      .then((res) => {
        const storesWithAppt = res.filter((store) => store.appointment !== 0)
        setRawStores(storesWithAppt)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  return (
    <StyledPrenotaAppuntamentoBody $showTY={!!showTY} className="std">
      <CanonicalUrlComponent url={`/assistenza/prenota-appuntamento`} />
      {showTY ? (
        <ThankYouPageComponent
          show={true}
          onCloseTYP={() => {
            setShowTY(undefined)
          }}
          onClose={() => {}}
          onDownloadICS={() =>
            handleOnDownloadICS(date, orarioLabel, selectedSpazioLabel)
          }
          data={showTY === 'ok' ? data?.typePageOK : data?.typePageKO}
          infoData={
            showTY === 'ok'
              ? {
                  data: date && getYYYYMMDD(date),
                  time: orarioLabel,
                  address: selectedSpazioLabel,
                }
              : undefined
          }
          ok={showTY}
        />
      ) : (
        <StyledContainer>
          <StyledAppuntamentoModal>
            <StyledAppuntamentoContent>
              <StyledAppuntamentoSidebar>
                {data && (
                  <>
                    {data.sidebarInformation.icon && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.sidebarInformation.icon,
                        }}
                      />
                    )}
                    {data.sidebarInformation.src && (
                      <img src={data.sidebarInformation.src} alt="icona" />
                    )}
                    <StyledHTMLSidebar
                      dangerouslySetInnerHTML={{
                        __html: data.sidebarInformation.text,
                      }}
                    />
                  </>
                )}
              </StyledAppuntamentoSidebar>
              {data && rawStores && (
                <MakeAppointment
                  data={data}
                  rawStores={rawStores}
                  privacyLink={privacyLink}
                  onSubmit={handleOnSubmit}
                />
              )}
            </StyledAppuntamentoContent>
          </StyledAppuntamentoModal>
        </StyledContainer>
      )}
      {/* <LoaderModal showModal={showLoader} /> */}
    </StyledPrenotaAppuntamentoBody>
  )
}

export default PrenotaAppuntamentoPage

export const StyledSelectFieldContainer = styled.div`
  display: flex;
  height: 100%;

  #selectedServizio {
    flex-grow: 1;
  }
`
