import { InputLocationModal } from 'components/Mappa/components/InputLocationModal'
import {
  StyledAppuntamentoForm,
  StyledSelectFieldContainer,
  StyledServiceWrapper,
  StyledServizioIcon,
  StyledStoreLabelWrapper,
} from './MakeAppointment.style'
import { Option, SelectField } from 'commons/FormComponents/SelectField'
import {
  StyledGeneralFormError,
  StyledGeneralLabel,
} from 'commons/FormComponents/General.style'
import { DatePicker } from 'components/UI/DatePicker'
import { Input } from 'commons/FormComponents/Input'
import { StyledPrivacy } from 'components/Mappa/components/AppuntamentoModal.style'
import {
  FilterSearchParams,
  addDays,
  capitalizeFirstLetter,
  detectDevice,
  eventDataLayer,
  filterStore,
  getCookie,
  getGA4PageSezione,
  getSearchParamsForFilters,
  getSpazioFilteredStores,
  getYYYYMMDD,
  isValidTelephone,
  prepareStringForDataLayer,
  removeTrailingSlashes,
  unescapeHTML,
} from 'commons/utils'
import { AtomButton } from 'atoms/AtomButton'
import { SendIcon } from 'components/Icons/SendIcon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AppointmentInformationResType,
  StoreDataResType,
} from 'models/stores-model'
import { Libraries, useLoadScript } from '@react-google-maps/api'
import {
  getAvailableTimesBasedOnTimetable,
  getServices,
  getStores,
} from 'components/Mappa/map-utils'
import { getIP } from 'commons/lib'
import { LoaderModal } from 'components/Modale/LoaderModal/LoaderModal'
import { StoreData } from 'components/Mappa/Mappa.types'
import slugify from '@sindresorhus/slugify'

type FilterCity = {
  label: string
  value?: string
  lat?: number
  lng?: number
}

export const MakeAppointment = ({
  data,
  rawStores,
  privacyLink,
  onSubmit,
}: {
  data: AppointmentInformationResType
  rawStores: StoreDataResType[]
  privacyLink?: string | null
  onSubmit: ({
    orario,
    date,
    selectedSpazioLabel,
    showTY,
  }: {
    orario: string | undefined
    date: Date | undefined
    selectedSpazioLabel: string | undefined
    showTY: 'ok' | 'ko'
  }) => void
}) => {
  const libraries = ['places'] as Libraries
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_KEY || '',
    libraries,
  })

  //#region STATES
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [selectedSpazioData, setSelectedSpazioData] =
    useState<StoreDataResType>()
  const [selectedSpazio, setSelectedSpazio] = useState<Option>()
  const [selectedServizio, setSelectedServizio] = useState<Option>()
  const [servizioOptions, setServizioOptions] = useState<Option[]>([])
  const [spazioOptions, setSpazioOptions] = useState<Option[]>([])
  const [paramCoordinates, setParamCoordinates] = useState<
    | {
        lat: number
        lng: number
      }
    | null
    | undefined
  >(null)
  const [selectedCityParams, setSelectedCityParams] = useState<FilterCity>()
  const [searchParams, setSearchParams] = useState<FilterSearchParams | null>(
    null
  )
  const [searchingType, setSearchingType] = useState<
    'location' | 'params' | null
  >('location')

  const [orario, setOrario] = useState<Option>()
  const [date, setDate] = useState<Date>()
  const [nome, setNome] = useState<string>('')
  const [cognome, setCognome] = useState<string>('')
  const [tel, setTel] = useState<string>('')
  const [selectedCity, setSelectedCity] = useState<Option>()
  const [selectedCityValue, setSelectedCityValue] = useState<Option>()
  const [showTY, setShowTY] = useState<'ok' | 'ko' | undefined>()

  const [errors, setErrors] = useState<Record<string, string>>({})
  const [error, setError] = useState<string>('')

  const [availableTimes, setAvailableTimes] = useState<Array<Option<string>>>(
    []
  )
  //#endregion

  //#region MEMO
  const bankHolidays = useMemo(() => {
    const holidays = []
    if (data?.bankHoliday && Array.isArray(data?.bankHoliday)) {
      holidays.push(...data.bankHoliday)
    }
    if (
      selectedSpazioData?.bankHoliday &&
      Array.isArray(selectedSpazioData?.bankHoliday)
    ) {
      holidays.push(...selectedSpazioData.bankHoliday)
    }

    return holidays
  }, [selectedSpazioData, data])

  const selectedSpazioTimetable = useMemo(() => {
    if (
      selectedSpazioData?.timetable &&
      selectedSpazioData.timetable.length > 0
    ) {
      const timetable = selectedSpazioData.timetable.map((time) => time.closed)
      /*
              Need to do this because the calendar considers the first element Sunday.
            */
      const lastElement = timetable.pop() ?? 0
      timetable.unshift(lastElement)
      return timetable
    } else {
      return null
    }
  }, [selectedSpazioData])
  //#endregion

  //#region HANDLERS
  function handleOnSubmit() {
    setError('')
    const isFormValid = validateForm()
    if (!isFormValid) {
      return
    }
    setShowLoader(true)
    createLeadMakeAppointment()
  }
  const checkInputDisabled = () => {
    return (
      (searchingType === 'location' && !selectedCity) ||
      (searchingType === 'params' &&
        !searchParams?.cityParam &&
        !searchParams?.provinceParam)
    )
  }
  async function createLeadMakeAppointment() {
    const ip = await getIP()

    const params = new URLSearchParams(window.location.search)
    const fntParam = params.get('fnt')
    const dfntParam = params.get('dfnt')
    const fntCookie = getCookie('fnt')
    const dfntCookie = getCookie('dfnt')
    const deviceType = detectDevice()

    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_CASA_URL
      )}/create_lead_make_appointment_rest_resource`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          cellular: tel,
          leadSource: 'Appuntamento A2C',
          privacy: 'no',
          type: 'make_appointment',
          values: {
            nome: nome,
            cognome: cognome,
            tel: tel,
            provincia: selectedCityValue?.label,
            servizio: selectedServizio?.label,
            store: selectedSpazio?.label,
            day: getYYYYMMDD(date),
            ora: orario?.label,
            ip_address: ip,
            cookie_dfnt: dfntParam ?? dfntCookie ?? 'diretto',
            cookie_fnt: fntParam ?? fntCookie ?? 'web',
            passed_id: '190001',
            product_appointment: true,
            field_tipo_device: capitalizeFirstLetter(deviceType),
          },
          nome: nome,
          cognome: cognome,
          day: getYYYYMMDD(date),
          ora: orario?.label,
          store: selectedSpazio?.label,
          passed_referrer: '',
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setShowTY('ok')
          eventDataLayer({
            event: 'store_locator',
            step: 'appuntamento_inviato_ok',
            label: prepareStringForDataLayer(selectedSpazio?.label ?? ''),
            sezione: getGA4PageSezione(),
          })
        } else {
          !!res.errors[0] &&
            res.errors[0].message &&
            setError(res.errors[0].message)
        }
      })
      .catch((err) => {
        console.error(err)
        setShowTY('ko')
        eventDataLayer({
          event: 'store_locator',
          step: 'appuntamento_inviato_ko',
          label: prepareStringForDataLayer(selectedSpazio?.label ?? ''),
          sezione: getGA4PageSezione(),
        })
      })
      .finally(() => {
        setShowLoader(false)
      })
  }
  function resetData() {
    setParamCoordinates(null)
    setSelectedCity(undefined)
    setSelectedCityValue(undefined)
    setSelectedCityParams(undefined)
    setSelectedServizio(undefined)
    setSelectedSpazio(undefined)
    setDate(undefined)
    setOrario(undefined)
    setNome('')
    setCognome('')
    setTel('')
  }
  function validateForm() {
    resetAllErrors()

    const errorsForm: Record<string, string> = {}

    if (!selectedCityValue) {
      Object.assign(errorsForm, { selectedCity: 'Campo obbligatorio' })
    }

    if (!selectedSpazio) {
      Object.assign(errorsForm, { selectedSpazio: 'Campo obbligatorio' })
    }

    if (!date) {
      Object.assign(errorsForm, { date: 'Campo obbligatorio' })
    }

    if (!orario) {
      Object.assign(errorsForm, { orario: 'Campo obbligatorio' })
    }

    if (nome.length === 0) {
      Object.assign(errorsForm, { nome: 'Campo obbligatorio' })
    }

    if (cognome.length === 0) {
      Object.assign(errorsForm, { cognome: 'Campo obbligatorio' })
    }

    if (tel.length === 0) {
      Object.assign(errorsForm, { tel: 'Campo obbligatorio' })
    }

    if (tel.length > 0 && !isValidTelephone(tel)) {
      Object.assign(errorsForm, { tel: 'Non è un numero di telefono valido' })
    }
    setErrors(errorsForm)

    const isFormValid = Object.entries(errorsForm).length === 0

    const errorKeyValue = Object.entries(errorsForm).find(
      ([, value]) => !!value
    )

    if (errorKeyValue) {
      const [key] = errorKeyValue
      const errorInputNode = document.getElementById(`${key}`)
      if (errorInputNode) {
        const topOffset = errorInputNode.getBoundingClientRect().top
        window.scrollBy({
          behavior: 'smooth',
          top: topOffset - 100,
        })
      }
    }

    return isFormValid
  }
  const clearError = (error: string) => {
    setErrors((d) => {
      delete d[error]
      return { ...d }
    })
  }
  //#endregion

  //#region CALLBACKS
  const resetAllErrors = useCallback(() => {
    setErrors({})
  }, [])

  const updateStores = useCallback(() => {
    const filterStores = getStores(
      rawStores,
      undefined, // selectedCity?.value,
      selectedServizio?.value
    )

    let filteredOptions = new Array<filterStore>()
    if (searchingType === 'location') {
      filteredOptions = getSpazioFilteredStores(
        {
          lat: selectedCityParams?.lat ?? null,
          lng: selectedCityParams?.lng ?? null,
        },
        filterStores
      )
      const options = filteredOptions?.length
        ? filteredOptions.map((store) => ({
            label: store.label ?? '',
            rowLabel: (
              <StyledStoreLabelWrapper>
                {store.label}
                <div className="km_content">{store.distanceFromCurrent} km</div>
              </StyledStoreLabelWrapper>
            ),
            value: store.id,
            type_of_store: store.type_of_store,
            partner_slug: store.partner_slug,
          }))
        : []
      setSpazioOptions(options)
      setSelectedSpazio(undefined)
    } else if (searchingType === 'params' && searchParams) {
      const rFilterStores = filterStores as StoreData[]
      handleSearchFilters(rFilterStores, searchParams)
    }
  }, [rawStores, selectedCity, selectedServizio, paramCoordinates])

  const updateServizi = useCallback(() => {
    const servizi = getServices(
      rawStores,
      undefined, // selectedCity?.value,
      selectedSpazio?.value
    )

    const options = servizi.map((servizio) => ({
      label: capitalizeFirstLetter(servizio.label),
      rowLabel: (
        <StyledServiceWrapper>
          <StyledServizioIcon
            dangerouslySetInnerHTML={{
              __html: unescapeHTML(servizio.iconsvg),
            }}
          />
          {capitalizeFirstLetter(servizio.label)}
        </StyledServiceWrapper>
      ),
      value: servizio.service_name?.toLocaleLowerCase(),
    }))

    setServizioOptions(options)
  }, [rawStores, selectedCity, selectedSpazio])
  //#endregion

  //#region EFFECTS
  useEffect(() => {
    setOrario(undefined)
    const standardAvailableTimes =
      data?.hoursAppointment.map((time) => ({ label: time, value: time })) || []
    const newRange = getAvailableTimesBasedOnTimetable(
      date,
      selectedSpazioData,
      standardAvailableTimes
    )
    setAvailableTimes(newRange)
  }, [date])

  useEffect(() => {
    updateServizi()
    updateStores()
  }, [rawStores])

  useEffect(() => {
    updateServizi()
    updateStores()
  }, [selectedCity])

  useEffect(() => {
    updateStores()
  }, [selectedServizio])

  useEffect(() => {
    updateServizi()
    setDate(undefined)
    setOrario(undefined)
  }, [selectedSpazio])

  useEffect(() => {
    const rawData = rawStores.find(
      (el) => Number(el.id) === Number(selectedSpazio?.value)
    )

    setSelectedSpazioData(rawData)
  }, [rawStores, selectedSpazio])

  useEffect(() => {
    setOrario(undefined)
    const standardAvailableTimes =
      data?.hoursAppointment.map((time) => ({ label: time, value: time })) || []
    const newRange = getAvailableTimesBasedOnTimetable(
      date,
      selectedSpazioData,
      standardAvailableTimes
    )
    setAvailableTimes(newRange)
  }, [date])

  useEffect(() => {
    if (showTY) {
      onSubmit({
        orario: orario?.value,
        date: date,
        selectedSpazioLabel: selectedSpazio?.label,
        showTY: showTY,
      })
      resetData()
    }
  }, [showTY])

  function handleSearchFilters(
    filterStores: StoreData[],
    result: FilterSearchParams
  ) {
    const filteredOptions: Array<filterStore> = getSpazioFilteredStores(
      {
        lat:
          result?.provinceCoordinates?.lat ??
          result?.cityCoordinates?.lat ??
          null,
        lng:
          result?.provinceCoordinates?.lng ??
          result?.cityCoordinates?.lng ??
          null,
      },
      filterStores,
      {
        tipo: result?.tipoParam,
        partner: result?.partnerParam,
        store: result?.storeParam,
      } ?? undefined,
      result?.cityCoordinates?.lat && result?.cityParam
        ? result?.cityParam
        : undefined,
      result?.provinceCoordinates?.lat && result?.provinceParam
        ? result?.provinceParam
        : undefined
    )

    const options = filteredOptions?.length
      ? filteredOptions.map((store) => ({
          label: store.label ?? '',
          rowLabel: (
            <StyledStoreLabelWrapper>
              {store.label}
              <div className="km_content">{store.distanceFromCurrent} km</div>
            </StyledStoreLabelWrapper>
          ),
          value: store.id,
          type_of_store: store.type_of_store,
          partner_slug: store.partner_slug,
        }))
      : []
    setSpazioOptions(options)
    setSelectedSpazio(undefined)
  }

  useEffect(() => {
    if (isLoaded && rawStores) {
      const filterStores: StoreData[] = getStores(
        rawStores,
        undefined, // selectedCity?.value,
        undefined
      ) as StoreData[]

      getSearchParamsForFilters(filterStores)
        .then((e: FilterSearchParams | null) => {
          if (e) {
            setSearchingType('params')
            const result = {
              provinceParam: e.provinceParam,
              provinceCoordinates: e.provinceCoordinates,
              cityParam: e.cityParam,
              cityCoordinates: e.cityCoordinates,
              tipoParam: e.tipoParam,
              partnerParam: e.partnerParam,
              storeParam: e.storeParam,
            }
            setSearchParams(result)

            if (result?.storeParam) {
              const optionsFiltered = (filterStores as StoreData[]).filter(
                (store) => {
                  if (result && result.storeParam && result.storeParam !== '') {
                    return slugify(store.title) === result.storeParam
                  }
                }
              )
              const optionsMapped = optionsFiltered.map((store) => ({
                label: store.title,
                rowLabel: <span>{store.title}</span>,
                value: store.id,
              }))

              if (optionsFiltered.length > 0)
                setSelectedCityValue({
                  label: optionsFiltered[0].comune,
                  value: optionsFiltered[0].comune.toLowerCase(),
                })
              if (optionsMapped.length > 0) setSelectedSpazio(optionsMapped[0])
              setSpazioOptions(optionsMapped)
            } else {
              handleSearchFilters(filterStores, result)
            }
          } else {
            setSearchingType('location')
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [isLoaded, rawStores])
  //#endregion

  return (
    <StyledAppuntamentoForm>
      <InputLocationModal
        province={selectedCity?.label}
        label="Indirizzo"
        isInModal
        filterFromSearchParams={
          searchingType === 'params' && searchParams
            ? {
                value: searchParams?.cityParam ?? searchParams?.provinceParam,
                lat:
                  searchParams?.provinceCoordinates?.lat ??
                  searchParams?.cityCoordinates?.lat ??
                  null,
                lng:
                  searchParams?.provinceCoordinates?.lng ??
                  searchParams?.cityCoordinates?.lng ??
                  null,
              }
            : null
        }
        onChange={(option) => {
          clearError('selectedCity')
          setParamCoordinates(null)
          setSelectedServizio(undefined)
          setSelectedSpazio(undefined)
          setSelectedCity(option)
          setSelectedCityValue(option)
          setSelectedCityParams(option)
        }}
        resetFilters={() => {
          setSearchParams(null)
          setSearchingType('location')
        }}
      />
      <StyledSelectFieldContainer>
        <SelectField
          id="selectedServizio"
          label="Servizio"
          placeholder="Seleziona il Servizio"
          options={servizioOptions}
          value={selectedServizio}
          onChange={(val) => {
            clearError('selectedServizio')
            setSelectedServizio(val)
          }}
          onFocus={() => {
            clearError('selectedServizio')
          }}
          disabled={checkInputDisabled()}
          error={errors.selectedServizio}
        />
      </StyledSelectFieldContainer>

      <SelectField
        id="selectedSpazio"
        style={{ gridColumn: '1 / -1' }}
        label="Spazio A2A*"
        placeholder="Seleziona lo spazio A2A"
        options={spazioOptions}
        value={selectedSpazio}
        onChange={(val) => {
          clearError('selectedSpazio')
          setSelectedSpazio(val)
        }}
        onFocus={() => {
          clearError('selectedSpazio')
        }}
        disabled={checkInputDisabled()}
        error={errors.selectedServizio}
      />
      <div>
        <StyledGeneralLabel $disabled={!selectedSpazioTimetable}>
          Data*
        </StyledGeneralLabel>
        <DatePicker
          id="date"
          date={date}
          label="Seleziona la data"
          disabledDays={selectedSpazioTimetable || [0, 0, 0, 0, 0, 0, 0, 0]}
          bankHolidays={bankHolidays}
          minDate={addDays(new Date(), 3)}
          onDateSelect={(date) => {
            clearError('date')
            setDate(date)
          }}
          onFocus={() => {
            clearError('date')
          }}
          disabled={!selectedSpazioTimetable}
          hasError={!!errors.date}
        />
      </div>
      <SelectField
        id="orario"
        label="Orario*"
        placeholder="Seleziona l'orario"
        options={availableTimes}
        value={orario}
        disabled={!date}
        error={errors.orario}
        onChange={(val) => {
          clearError('orario')
          setOrario(val)
        }}
        onFocus={() => {
          clearError('orario')
        }}
      />
      <Input
        id="nome"
        value={nome}
        onChange={(val) => {
          clearError('nome')
          setNome(val)
        }}
        onFocus={() => {
          clearError('nome')
        }}
        label="Nome"
        placeholder="Nome"
        required
        error={errors.nome}
      />
      <Input
        id="cognome"
        value={cognome}
        onChange={(val) => {
          clearError('cognome')
          setCognome(val)
        }}
        onFocus={() => {
          clearError('cognome')
        }}
        label="Cognome"
        placeholder="Cognome"
        required
        error={errors.cognome}
      />
      <Input
        id="tel"
        onChange={(val) => {
          clearError('tel')
          setTel(val)
        }}
        onFocus={() => {
          clearError('tel')
        }}
        value={tel}
        label="Numero di telefono"
        placeholder="Numero di telefono"
        type="tel"
        required
        error={errors.tel}
      />
      <StyledPrivacy>
        <p>
          Prendi visione dell&lsquo;{' '}
          <a
            href={
              privacyLink ||
              `${removeTrailingSlashes(
                process.env.A2A_DRUPAL_CASA_URL
              )}/sites/default/files/NoIndex/documenti/InformativaPrivacy/Info_Privacy_Presa_Appuntamento.pdf`
            }
            target="_blank"
            rel="noreferrer"
          >
            Informativa privacy
          </a>
        </p>
        <p className="recaptcha">
          Questo sito è protetto da reCAPTCHA e su di esso si applicano l&lsquo;
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Informativa sulla privacy
          </a>{' '}
          e i{' '}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Termini del servizio
          </a>{' '}
          di Google.
        </p>
        <p className="campi-obbligatori">*Campi obbligatori</p>
      </StyledPrivacy>

      <AtomButton
        label="Invia"
        onClick={handleOnSubmit}
        icon={<SendIcon />}
        iconPosition="right"
        onDataLayer={() => {
          eventDataLayer({
            event: 'store_locator',
            step: 'invio_appuntamento',
            label: prepareStringForDataLayer(selectedSpazio?.label ?? ''),
            sezione: getGA4PageSezione(),
          })
        }}
      />

      {error.length > 0 && (
        <StyledGeneralFormError
          style={{ gridColumn: '1 / -1', textAlign: 'center' }}
        >
          {error}
        </StyledGeneralFormError>
      )}
      <LoaderModal showModal={showLoader} />
    </StyledAppuntamentoForm>
  )
}
