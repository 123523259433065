import styled from 'styled-components'
import {
  BPSizes,
  Colors,
  ColumnsGap,
  Palette,
  Rem,
  StyledHTMLTags,
  StyledHeadingH4,
  Typography,
} from 'commons/Theme'
import { Container } from 'commons/Container'

export const StyledPrenotaAppuntamentoBody = styled.div<{ $showTY?: boolean }>`
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ $showTY }) => ($showTY ? Colors.azure600 : 'white')};
`

export const StyledSelectFieldContainer = styled.div`
  display: flex;
  height: 100%;

  #selectedServizio {
    flex-grow: 1;
  }
`

export const StyledServizioIcon = styled.div`
  stroke: ${Colors.azure600};
`

export const StyledStoreLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${Rem(10)};

  .km_content {
    font-size: ${Rem(14)};
    font-weight: 500;
    line-height: 135%;
    color: ${Colors.azure600};
  }
`

export const StyledServiceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${Rem(14)};
`

export const StyledContentContainer = styled(Container)`
  flex: 1;
  display: flex;
  align-items: center;
`

export const StyledHTMLSidebar = styled(StyledHTMLTags)`
  h1 {
    color: ${Colors.azure600};
    font-size: ${Rem(28)};

    @media screen and (min-width: ${BPSizes.desktop}px) {
      font-size: ${Rem(40)};
    }
  }
`

export const StyledContainer = styled(Container)`
  height: initial;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    height: 100%;
  }
`

export const StyledAppuntamentoModal = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  position: relative;
  text-align: left;
`

export const StyledAppuntamentoContent = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 40px;
  width: 100%;

  grid-template-columns: repeat(12, 1fr);

  padding: 80px 0;

  @media screen and (min-width: ${BPSizes.md}px) {
    column-gap: ${ColumnsGap.md};
    row-gap: 24px;
  }
`

export const StyledAppuntamentoSidebar = styled.div`
  ${StyledHeadingH4};
  color: ${Palette.Greyscale[800]};

  p {
    ${StyledHeadingH4};
  }

  grid-column: 1 / span 12;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    grid-column: 1 / span 4;
  }

  h1 {
    ${Typography.Heading04.sm};
    line-height: 1.2;
    color: ${Palette.Blue[800]};
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 1.2;

    @media screen and (min-width: ${BPSizes.md}px) {
      ${Typography.Heading02.md};
    }

    @media screen and (min-width: ${BPSizes.lg}px) {
      ${Typography.Heading02.xlg};
      font-weight: 400;
    }
  }

  small {
    margin-top: 16px;
    font-size: ${Rem(12)};
    line-height: 130%;
    color: ${Colors.grey700};
  }

  a {
    color: ${Colors.azure600};
    &:hover {
      color: ${Colors.blue400};
    }
  }
`

export const StyledAppuntamentoForm = styled.div`
  grid-column: 1 / span 12;

  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  column-gap: ${ColumnsGap.sm};
  row-gap: 20px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    grid-column: 6 / span 6;
  }

  @media screen and (min-width: ${BPSizes.md}px) {
    column-gap: ${ColumnsGap.md};
  }
`
