import { useStaticQuery, graphql } from 'gatsby'

export const usePrivacyLinks = () => {
  const { allA2APrivacyLinks } = useStaticQuery<PrivacyLinkTypeQueryType>(
    graphql`
      query PrivacyLinksQuery {
        allA2APrivacyLinks {
          nodes {
            __typename
            id
            privacy_id
            url
          }
        }
      }
    `
  )
  return allA2APrivacyLinks.nodes
}

type PrivacyLinkTypeQueryType = {
  allA2APrivacyLinks: {
    nodes: PrivacyLinkType[]
  }
}

type PrivacyLinkType = {
  __typename: string
  id: string
  privacy_id: string
  url: string
}
